import Vue from 'vue'
import VueI18n from 'vue-i18n'
import lang from '../lang/index'
Vue.use(VueI18n)
// 引入各个语言配置文件
// 创建vue-i18n实例i18n
const i18n = new VueI18n({
// 设置默认语言
  locale: 'en',
  messages: {
    en: lang.en,
    br: lang.br,
    silentTranslationWarn: true
  }

})
export default i18n
