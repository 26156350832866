export default {
    AfriendPhone: 'A friend Phone number:',
    wheelget: 'Click on the spinning wheel to get',
    KSH: 'UGX',
    takeout: 'Take-out',
    only: 'Only need to get another',
    towithdraw: 'to withdraw',
    Expira: 'Expira em',
    Invite: 'Invite a friend to help you withdraw ',
    allbets: 'All Bets',
    Leaderboard: 'Leaderboard',
    rank: 'Rank',
    user: 'User',
    betamount: 'Bet Amount',
    pddDesc: 'Event Rules ',
    pddDesc1: '1. The cumulative amount reaches R$100 to be withdrawn to your account. ',
    pddDesc2: '2. Invite your friends to register at betgo.con, this will help you to withdraw cash quickly. If your friend registers and plays the game, it will help you withdraw cash even faster.',
    pddDesc3: '3.Each user will receive one free spin per day.',
    pddDesc4: '4. Each user is valid for 3 days per event, and can participate in the event multiple times.',
    pddDesc5: '5. lf you are rewarded with the event amount, you can play the game for free and win more real money.',
    pddDesc6: '6. lf the withdrawal is not made within 3 days, the money will be lost.',
    GetKsh: 'Get UGX ',
    frofree: ' for free',
    SACAR: 'SACAR',
    takewrong: 'take wrong',
    FRIENDEARN: 'HELP A FRIEND EARN',
    Afterregistering: 'After registering, you can participate in activities to eam KSH',
    aftersign: 'After sign-in, you can also After registering, you can participate in the event to earn',
    invitedyou: 'invited you to join the event to earn',
    helphim: 'Sign into help him boost to earn',
    Helpfriend: 'Help a friend earn' ,
    youfriend: 'Your friend' ,
    pleasetry: 'please try again',
}